var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-4"},[_vm._v("las la-bug")]),_vm._v("Errors "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"las la-search","label":"Search","single-line":"","hide-details":""},on:{"click:append":_vm.getErrors,"keyup":_vm.searchKeyUp},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.getErrors}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("las la-sync")]),_vm._v("Refresh ")],1),_c('error-log')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.model.errors,"search":_vm.search,"server-items-length":_vm.totalCount,"options":_vm.options,"loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm.dayjs(item.date).format("YYYY-MM-DD HH:mm:ss")))]}},{key:"item.licenseId",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("SHOW")])]}}],null,true)},[_c('pre',[_vm._v(_vm._s(item.licenseId))])])]}},{key:"item.message",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.message))])]}}],null,true)},[_c('span',[_vm._v("User agent: "+_vm._s(item.userAgent ? item.userAgent : "none")),_c('br'),_vm._v("IP: "+_vm._s(item.ip ? item.ip : "none"))])])]}},{key:"item.licenseKey",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("SHOW")])]}}],null,true)},[_c('pre',[_vm._v(_vm._s(item.licenseKey))])])]}},{key:"item.registeredDomains",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("SHOW")])]}}],null,true)},_vm._l((item.registeredDomains),function(domain){return _c('span',{key:domain,staticClass:"domain"},[_vm._v(_vm._s(domain))])}),0)]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","circle":"","dark":"","color":"red"},on:{"click":function($event){return _vm.removeError(item)}}},[_c('v-icon',[_vm._v("las la-trash")])],1)]}}])}),_c('v-snackbar',{attrs:{"top":"","color":_vm.snackbar.color,"rounded":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.snackbar.icon))]),_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }