













































































import { Component, Vue, Watch } from "vue-property-decorator";
import state from "../../State";
import ErrorLog from "./ErrorLog.vue";
import { FrontendLicenseError } from "../../../../types";
import dayjs from "dayjs";

@Component({
  name: "Errors",
  components: { ErrorLog },
})
export default class Payments extends Vue {
  headers = [
    { align: "left", text: "Date", value: "date" },
    { align: "left", text: "Origin", value: "origin" },
    { align: "left", text: "Message", value: "message" },
    { align: "left", text: "Registered domains", value: "registeredDomains" },
    { align: "left", text: "License id", value: "licenseId" },
    { align: "left", text: "License email", value: "email" },
    { align: "left", text: "License key", value: "licenseKey" },
    { align: "left", text: "Actions", value: "actions" },
  ];
  model: { errors: FrontendLicenseError[] } = { errors: [] };
  snackbar = {
    visible: false,
    color: "wraning",
    message: "",
    icon: "",
  };
  search = "";
  dayjs = dayjs;
  totalCount = 0;
  options = { page: 1, itemsPerPage: 15 };
  loading = false;

  @Watch("options", { deep: true })
  public watchOptions(options: any) {
    this.getErrors();
  }

  getErrors() {
    this.loading = true;
    let { itemsPerPage } = this.options;
    if (!itemsPerPage) itemsPerPage = 15;
    const skip = this.options.page * itemsPerPage - itemsPerPage;
    void fetch(state.get("request.url.errors") + `?skip=${skip}&limit=${itemsPerPage}&search=${this.search}`, {
      ...state.get("request.params.api.get"),
    })
      .then((res) => res.json())
      .then((data) => {
        this.totalCount = data.totalCount;
        this.model.errors = data.errors.map((error: FrontendLicenseError) => {
          if (error.licenses && error.licenses.length) {
            const license = error.licenses[error.licenses.length - 1];
            error.licenseId = license._id || error.licenseId;
            error.email = license.email || error.email;
            error.licenseKey = license.license.key || error.licenseKey;
            if (!error.registeredDomains && error.licenses && error.licenses.length && error.licenses[0].domains) {
              error.registeredDomains = error.licenses[0].domains;
            }
          }
          return error;
        });
      })
      .then(() => {
        this.loading = false;
      });
  }

  removeError(error: FrontendLicenseError) {
    fetch(state.get("request.url.errors") + "/" + error._id, {
      ...state.get("request.params.api.delete"),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          this.model.errors = this.model.errors.filter((current: FrontendLicenseError) => current._id !== error._id);
          this.snackbar.message = data.message;
          this.snackbar.color = "success";
          this.snackbar.icon = "las la-check-square";
          this.snackbar.visible = true;
        } else {
          this.snackbar.visible = true;
          this.snackbar.color = "error";
          this.snackbar.icon = "las la-bug";
          this.snackbar.message = data.message;
        }
      });
  }

  searchKeyUp(key: KeyboardEvent) {
    if (key.keyCode === 13) {
      this.getErrors();
    }
  }
}
