






































import { Vue, Component } from 'vue-property-decorator';
import state from '../../State';

@Component({
  name: 'ErrorLog'
})
export default class ErrorLog extends Vue {
  dialog = false;
  tab = null;
  items = [
    'public-license-api',
    'documentation-home',
    'gstc-admin',
    'old-statistics',
    'backup'
  ];
  logs = {};

  created() {
    void fetch(state.get('request.url.error-log'), {
      ...state.get('request.params.api.get')
    })
      .then(res => res.json())
      .then(data => {
        this.logs = data.logs;
      });
  }
}
